import React, { useState, useEffect } from 'react';
import { useQueryParam } from 'gatsby-query-params';
import { Alert } from '@material-ui/lab';
import { Button, LinearProgress } from '@material-ui/core';

import { getOrderByPaymentReference } from '../../api/dataApi';

import './index.css';

function PaymentComplete({ paymentIntentFunc }) {
  const paymentReferenceId = useQueryParam(
    'payment_intent_client_secret',
    null
  );

  const [order, setOrder] = useState(null);
  useEffect(() => {
    if (paymentReferenceId) {
      getOrderByPaymentReference(paymentReferenceId).then((result) =>
        setOrder(result)
      );
    }
  }, [paymentReferenceId]);

  return (
    <div className="page-container payment-complete">
      <h1>Payment Complete</h1>
      <div className="page">
        <div className="inner">
          {order && order.PaymentAmountConfirmed ? (
            <Alert severity="success">
              <p>
                Thank you! Your payment{' '}
                {order.PaymentAmountConfirmed
                  ? ' in the amount of $' +
                    order.PaymentAmountConfirmed.toFixed(2)
                  : ''}{' '}
                {order.OrderNumber
                  ? ' for order #' + order.OrderNumber + ' '
                  : ''}{' '}
                was processed successfully.
              </p>
              <Button variant="contained" color="primary" href="/dashboard">
                Return to Dashboard
              </Button>
            </Alert>
          ) : order ? (
            order.OrderStatusID <= 5 ? (
              <Alert severity="warning">
                Your payment is being processed. We'll send you a confirmation
                email once the payment is complete.{' '}
                <Button variant="contained" color="primary" href="/dashboard">
                  Return to Dashboard
                </Button>
              </Alert>
            ) : (
              <Alert severity="error">
                Your payment was declined
                {order.PaymentDeclineReason
                  ? ' for the following reason:'
                  : '.'}
                {order.PaymentDeclineReason ? (
                  <ul>
                    <li>{order.PaymentDeclineReason}</li>
                  </ul>
                ) : (
                  <>
                    <br />
                    <br />
                  </>
                )}
                <a className="link" href="/dashboard">
                  Click here
                </a>{' '}
                to return to your dashboard and try again.
              </Alert>
            )
          ) : (
            <LinearProgress />
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentComplete;
